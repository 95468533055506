const url = {
  // question
  question_list: '/bank/v1/question',
  question_create: '/bank/v1/question',
  question_update: '/bank/v1/question/{id}',
  question_delete: '/bank/v1/question/{id}',
  question_detail: '/bank/v1/question/detail/{id}',
  question_detail_public: '/bank/v1/question/public/{id}',

  // users
  admin_login: '/users/v1/admin/login',
  admin_update_password: '/users/v1/admin/change-password',

  // course
  course_weeks: '/course/v1/weeks',
  course_weeks_activity: '/course/v1/activities',
  course_submit_answer: '/course/v1/answer',

  // storage
  media_list: '/storage/v1/media',
  media_detail: '/storage/v1/media/detail/{id}',
  media_detail_public: '/storage/v1/media/public/{id}',
  media_upload: '/storage/v1/media/upload',

  // exam / epot
  exam_index: '/exam/v1',
  exam_list: '/exam/v1/list',
  exam_detail: '/exam/v1/detail/{id}',
  exam_create: '/exam/v1/create',
  exam_update: '/exam/v1/update/{id}',
  exam_delete: '/exam/v1/delete/{id}',
  exam_next_code: '/exam/v1/next-code',

  // certificate
  certificate_list: '/users/v1/admin/list-sertificate',
  certificate_detail: '/users/v1/admin/order/detail-full/{id}',

  // section
  section_list: '/section/v1/list',
  section_creeate: '/section/v1/creeate',
  section_detail: '/section/v1/detail/{id}',
  section_update: '/section/v1/update/{id}',

  // License
  license_list: '/license/v1/list',
  license_detail: '/license/v1/detail/{code}',
  license_book: '/license/v1/mark_as_sold',
  license_delete: '/license/v1/delete/{code}',
  license_order: '/license/v1/order_list/',

    // License_order
    license_order_list: '/users/v1/admin/order/list',
    license_order_detail: '/order/v1/detail/{code}',
    license_order_delete: '/users/v1/admin/order/sertificate/delete-sertificate',

  // FORNEXT: Hanya untuk keperluan demo
  get_id: () => 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }),
  success: (data) => ({
    status: 200,
    message: 'success',
    data,
    validation: {}
  }),
  error: (validation) => ({
    response: {
      data: {
        status: 400,
        message: 'error',
        data: {},
        validation
      }
    }
  }),
}

export default url