<template>
  <!-- FORNEXT: Dibuat satu template layout untuk import question (single/group) -->
  <v-row>
    <!-- FORNEXT: Dibuat model nested routing -->
    <v-col class="d-flex py-0" cols="12">
      <!-- FORNEXT: Font weight berubah ketika digabungkan dengan auth -->
      <div class="title my-auto">Daftar soal</div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-card-actions class="py-0">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="color-navy-soft text-capitalize"
            >
              Sort by
              <v-icon right>$arrdown</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-menu>
      <v-text-field
        label="Search"
        hide-details
        class="pt-1 mr-3 color-navy-soft mx-width-85 search-field"
        v-model="search"
        @keypress.enter="filter = search"
        @click:append="filter = search"
        ><v-icon slot="append" class="color-navy-soft"
          >$search</v-icon
        ></v-text-field
      >
    </v-col>

    <v-col cols="12">
      <v-btn text style="background: #fee9d3" color="primary" @click="back">
        Kembali
      </v-btn>
      <v-spacer class="px-1 d-inline-block"></v-spacer>
      <v-btn
        elevation="0"
        v-if="!_.isEmpty(selected)"
        color="primary"
        @click="importQuestion"
      >
        Add soal to test
      </v-btn>
    </v-col>

    <v-col cols="12" class="pt-0">
      <!-- hide-default-footer -->
      <v-data-table
        class="spacing-y-5 transparent"
        :mobile-breakpoint="0"
        hide-default-header
        item-key="id"
        single-expand
        v-model="selected"
        :server-items-length="questions.total"
        :items-per-page.sync="limit"
        :page.sync="page"
        :headers="[{ value: 'question.text' }]"
        :items="questions.list"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        :headers-length="3"
      >
        <template
          slot="item"
          slot-scope="{ item, isSelected, select, isExpanded, expand }"
          class="d-block"
        >
          <tr class="flat-b-1 rounded-lg">
            <td class="white rounded-l-lg">
              <v-simple-checkbox
                color="primary"
                v-model="item.selected"
                @click="select(!isSelected)"
              ></v-simple-checkbox>
            </td>
            <td class="white">
              <div :style="`width: ${questionTextWidth}px`" class="text-truncate">
                {{ item.question.text }}
              </div>
            </td>
            <td class="white rounded-r-lg">
              <v-btn icon @click="expand(!isExpanded)">
                <v-icon
                  :style="{ transform: isExpanded ? 'rotate(-180deg)' : '' }"
                  >$expand</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>
        <template slot="expanded-item" slot-scope="{ item }">
          <tr>
            <td colspan="3" class="flat-b-1 white rounded-lg">
              <v-col :style="`width: ${contentWidth}px`">
                <epot-input-soal :data="item" disabled />
              </v-col>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import EpotInputSoal from "../epot/components/EpotInputSoal.vue";

export default {
  name: "import-questions",
  components: { EpotInputSoal },
  data() {
    return {
      table_loading: false,
      selected: [],
      expanded: [],
      expand: null,
      filter: "",
      search: null,
      questions: {
        list: [],
        total: 0,
      },

      // Pagination
      limit: 10,
      page: 1,
    };
  },
  computed: {
    contentWidth() {
      let sidebar = !this.$vuetify.breakpoint.mdAndUp || 256;
      return Number(this.$vuetify.breakpoint.width) - sidebar -90
    },
    questionTextWidth() {
      return this.contentWidth - 158;
    }
  },
  created() {
    this.fetchQuestion();
  },
  methods: {
    back() {
      this.$router.push(this.$route.path.split("/").slice(0, -1).join("/"));
    },
    fetchQuestion() {
      this.table_loading = true;
      this.questions.list = [];
      
      this.$store
        .dispatch("question/list", {
          page: this.page,
          limit: this.limit,
          type: 'single_choice',
          status: 'publish'
        })
        .then((res) => {
          this.questions = res.data;
          this.table_loading = false;
        })
        .catch((error) => {
          this.table_loading = false;
          throw new Error(error)
        });
      // this.table_loading = false;
    },
    importQuestion() {
      let index = null;
      if (this.$route.params.index != undefined) {
        index = this.$route.params.index;
      }
      this.$emit("import-questions", this.selected, index);
      this.back();
    },
  },
  watch: {
    page() {
      this.fetchQuestion();
    },
    limit() {
      this.fetchQuestion();
    },
  },
};
</script>
