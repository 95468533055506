import { render, staticRenderFns } from "./EpotDetail.vue?vue&type=template&id=3296b282&"
import script from "./EpotDetail.vue?vue&type=script&lang=js&"
export * from "./EpotDetail.vue?vue&type=script&lang=js&"
import style0 from "./EpotDetail.vue?vue&type=style&index=0&id=3296b282&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports