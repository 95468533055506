<template>
  <v-row>
    <!-- FORNEXT: Dibuat model nested routing -->
    <v-col class="d-flex py-0" cols="12">
      <!-- FORNEXT: Font weight berubah ketika digabungkan dengan auth -->
      <div class="title my-auto">Daftar grup soal</div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-card-actions class="py-0">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="color-navy-soft text-capitalize"
            >
              Sort by
              <v-icon right>$arrdown</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-menu>
      <v-text-field
        label="Search"
        hide-details
        class="pt-1 mr-3 color-navy-soft mx-width-85 search-field"
        v-model="search"
        @keypress.enter="filter = search"
        @click:append="filter = search"
        ><v-icon slot="append" class="color-navy-soft"
          >$search</v-icon
        ></v-text-field
      >
    </v-col>

    <v-col cols="12">
      <v-btn text style="background: #fee9d3" color="primary" @click="back">
        Kembali
      </v-btn>
      <v-spacer class="px-1 d-inline-block"></v-spacer>
      <v-btn
        elevation="0"
        v-if="!_.isEmpty(selected)"
        color="primary"
        @click="importGroups"
      >
        Add group to test
      </v-btn>
    </v-col>

    <v-col cols="12" class="pt-0">
      <v-data-table
        class="spacing-y-5 transparent"
        :mobile-breakpoint="0"
        hide-default-header
        item-key="id"
        single-expand
        v-model="selected"
        :server-items-length="groups.total"
        :items-per-page.sync="limit"
        :page.sync="page"
        :headers="[{ value: 'text' }]"
        :items="filteredGroups"
        :loading="table_loading"
      >
        <template
          slot="item"
          slot-scope="{ item, isSelected, select, isExpanded, expand }"
          class="d-block"
        >
          <tr class="flat-b-1 rounded-lg">
            <td class="white rounded-l-lg">
              <v-simple-checkbox
                color="primary"
                v-model="item.selected"
                @click="select(!isSelected)"
              ></v-simple-checkbox>
            </td>
            <td class="white">
              <div
                :style="`width: ${contentWidth}`"
                class="text-truncate"
                :class="!!item.text || 'color--text text--lighten-4'"
              >
                {{ item.text || "(No instruction)" }}
              </div>
            </td>
            <td class="white rounded-r-lg">
              <v-btn icon @click="expand(!isExpanded)">
                <v-icon
                  :style="{ transform: isExpanded ? 'rotate(-180deg)' : '' }"
                  >$expand</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>
        <template slot="expanded-item" slot-scope="{ item }">
          <tr>
            <td colspan="3" class="flat-b-1 white rounded-lg">
              <v-col>
                <epot-input-grup-soal :data="item" disabled />
              </v-col>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import EpotInputGrupSoal from "../epot/components/EpotInputGrupSoal.vue";

export default {
  name: "import-question-groups",
  components: { EpotInputGrupSoal },
  data() {
    return {
      table_loading: false,
      selected: [],
      expanded: [],
      expand: null,
      filter: "",
      search: null,
      groups: {
        list: [],
        total: 0,
      },

      // Pagination
      limit: 10,
      page: 1,
    };
  },
  computed: {
    contentWidth() {
      let sidebar = !this.$vuetify.breakpoint.mdAndUp || 256;
      return Number(this.$vuetify.breakpoint.width) - 248 - sidebar + "px";
    },
    paginations() {
      let page = this.page,
        pageCount = this.totalPage,
        delta = 2,
        left = page - delta,
        right = page + delta + 1,
        result = [];

      result = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
        (i) => i && i >= left && i < right
      );

      if (result.length > 1) {
        // Add first page and dots
        if (result[0] > 1) {
          if (result[0] > 2) {
            result.unshift("...");
          }
          result.unshift(1);
        }

        // Add dots and last page
        if (result[result.length - 1] < pageCount) {
          if (result[result.length - 1] !== pageCount - 1) {
            result.push("...");
          }
          result.push(pageCount);
        }
      }

      return result;
    },
    totalEntries() {
      return this.groups.total;
    },
    totalPage() {
      return Math.ceil(this.totalEntries / this.limit);
    },
    first() {
      return Math.min(1 + (this.page - 1) * this.limit, this.totalEntries);
    },
    last() {
      return Math.min(this.page * this.limit, this.totalEntries);
    },
    filteredGroups() {
      return this.groups.list.filter((group) =>
        (group.text || "").includes(this.filter)
      );
    },
    paginatingGroups() {
      return this.filteredGroups
        .slice()
        .splice(this.limit * (this.page - 1), this.limit);
    },
  },
  created() {
    this.fetchGroup();
  },
  methods: {
    back() {
      this.$router.push(this.$route.path.split("/").slice(0, -1).join("/"));
    },
    fetchGroup() {
      this.table_loading = true;
      this.$store
        .dispatch("grouped_question/list", {
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          this.groups = res.data;
          this.$set(
            this.groups,
            "list",
            this.groups.list.map((group) => ({
              ...group,
              list_question: group.list_question.map((id) => ({ id: id })),
            }))
          );
          this.table_loading = false;
        })
        .catch(() => {
          this.table_loading = false;
        });
    },
    importGroups() {
      // console.log(this.selected);
      this.$emit("import-questions", this.selected);
      this.back();
    },
  },
  watch: {
    page() {
      this.fetchGroup();
    },
    limit() {
      this.fetchGroup();
    },
  },
};
</script>
