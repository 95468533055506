var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.loading)?[_c('v-col',{attrs:{"md":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"paragraph"}})],1)]:(_vm.error)?[_c('v-col',[_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.error)+" "),_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","elevation":"0"},on:{"click":_vm.fetchQuestion}},[_c('v-icon',[_vm._v("$refresh")])],1)],1)])]:[_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Pertanyaan")]),_c('v-textarea',{staticClass:"mb-2",attrs:{"readonly":_vm.isDisabled,"outlined":"","filled":"","color":"primary","hide-details":"auto"},model:{value:(_vm.question.question.text),callback:function ($$v) {_vm.$set(_vm.question.question, "text", $$v)},expression:"question.question.text"}}),_c('answer-type',{attrs:{"disabled":_vm.isDisabled,"type":_vm.question.type,"data":_vm.question},on:{"update":function($event){return Object.assign(_vm.question, $event)}}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Attachment")]),_c('v-row',{staticStyle:{"max-width":"100%"},attrs:{"no-gutters":""}},[(_vm.isMediaSet('image'))?_c('image-attachment',{staticClass:"mr-2 d-inline-block",attrs:{"disabled":_vm.isDisabled,"src":_vm.question.question},on:{"update":function($event){return _vm.$set(_vm.question, 'question', {
              ..._vm.question.question,
              ...$event,
            })}}}):_vm._e(),(_vm.isMediaSet('audio'))?_c('audio-attachment',{staticClass:"d-inline-block",attrs:{"disabled":_vm.isDisabled,"src":_vm.question.question},on:{"update":function($event){return _vm.$set(_vm.question, 'question', {
              ..._vm.question.question,
              ...$event,
            })}}}):_vm._e(),(_vm.isDisabled && _vm._.isEmpty(_vm.question.question.media))?_c('span',[_vm._v(" "+_vm._s("No media")+" ")]):_vm._e()],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }