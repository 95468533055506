import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    exam: require("./_exam").default,
    question: require("./_question").default,
    grouped_question: require("./_grouped_question").default,
    media: require("./_media").default,
    section: require("./_section").default,
    license: require("./_license").default,
    license_order: require("./_license-order").default,
    order: require("./_order").default,
    auth: require("./_auth.v1").default,
  },
});
